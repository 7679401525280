<template>
  <div style="background: #2d63cb;color: #ccc;text-align: left;height: 220px;padding-top:8px;">
    <a-row style="margin: 15px 0px;font-size: 15px" type="flex" justify="center" align="top">
      <div style="width: 600px;">
        <a target="_blank" style="color:#ccc;margin-right: 5px;height: 35px;line-height: 35px" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2021004457号</a>
        <a target="_blank" style="color:#ccc;height: 35px;line-height: 35px" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35011102350727">
          <img style="padding-right: 2px" src="@/assets/beian.png" />
          <span>闽公网安备 35011102350727号</span>
        </a>
        <div style="height: 35px;line-height: 35px">地址：福建省福州市晋安区茶园街道水头路7号邮政大厦1107-1110室</div>
        <div style="height: 35px;line-height: 35px">邮编：350013 联系电话：0591-87590087</div>
      </div>
      <div style="display: flex;flex: 0">
        <div style="height: 100px;margin-right: 25px;text-align:center;">
          <img style="height: 100px;width: 100px;margin: 0 auto;background: #fff;" src="@/assets/gzh.png">
          <div style="text-align: center;font-size: 10px;white-space: nowrap;">标准化界</div>
        </div>
        <div style="background: rgb(255 255 255 / 95%);height: 100px;margin-right: 15px">
          <img style="height: 100px;width: 100px;margin: 0 auto;background: #fff;" src="@/assets/xcx.png">
          <div style="text-align: center;font-size: 10px;white-space: nowrap;">标准编辑软件 SES</div>
        </div>
      </div>
    </a-row>
    <hr style="margin-top:38px;width: 100%;background-color:#9b9ba77a;height:1px;border:none;"/>
    <div style="height: 25px;line-height: 25px;text-align: center">©版权所有：福建省标院信息技术有限公司</div>
  </div>

</template>

<script>
export default {
  name: "FooterItem",

}
</script>

<style scoped>

</style>
